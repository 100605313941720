import data_archive_repository from '../assets/images/data_archive_repository.svg'
import data_analytics from '../assets/images/data_analytics.svg'
import public_dashboard from '../assets/images/public_dashboard.svg'
import reports from '../assets/images/reports.svg'

const InfoCardData = [
  {
    index:1,
    contentTitle: "Data Archive Repository",
    contentText: "Archive data can be digitized and saved in a customizable " +
    "data repository. The repository can be structured to take data " +
    "in the format defined by your in-house policies.",
    contentImage: data_archive_repository
  },
  {
    index:2,
    contentTitle: "Data Analytics",
    contentText: "Draw better and deep insights from your data by using the " +
    "data analytics features built into the platform. The data " +
    "visualisation builder offers you the flexibility to present your " +
    "data in any form you desire.",
    contentImage: data_analytics
  },
  {
    index:3,
    contentTitle: "Public Dashboard",
    contentText: "Create custom dashboards that are accessible to the public. " + 
    "This is to make your public data’s availability dynamic and easily " +
    "customisable.",
    contentImage: public_dashboard
  },
  {
    index:4,
    contentTitle: "Reports",
    contentText: "Quickly pull out custom reports from the repository. High level " +
    "charts and tables can easily be extracted from the large and " +
    "complex pool of data stored on the platform. ",
    contentImage: reports
  }
]

export default InfoCardData;