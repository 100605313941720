import React from 'react'
import './InfoCard.css'

const InfoCard = (props) =>
{
    return (
        <div key ={props.id} style={{background:'#FFFFFF',display:'flex', boxShadow:'1px 1px 3px rgba(20,20,20,0.3)', justifyContent:'flex-start',padding:0, border:0, borderRadius:5, overflow:'hidden', margin:5, width:'100%', maxWidth:600}}>
                        {
                          (props.index % 2 != 0) ? <div align="left" style={{display:'inline-block',align:'left', background:'#ffe2be'}}>
                            <img src={props.contentImage} alt="image" style={{margin:30, height:80, width:80}} />
                        </div> : null
                        }
                        <div style={{display:'block', margin:20, verticalAlign:'top',display:'flex', justifyContent:'flex-start',flexDirection: 'column', width:'100%', alignItems:'flex-start'}}>
                            
                            <div style={{fontWeight:'normal', color:"#ca7a17",  fontSize:24, marginBottom:10 }}>{props.contentTitle}</div>
                            <div style={{fontWeight:'normal', textAlign:'left',color:"#777777", fontSize:14}}>{props.contentText}</div>
                            
                        </div>
                        {
                          (props.index % 2 == 0) ? <div align="left" style={{display:'inline-block',align:'left', background:'#ffe2be'}}>
                            <img src={props.contentImage} alt="image" style={{margin:30, height:80, width:80}} />
                        </div> : null
                        }
                    </div>
    )
}

export default InfoCard;