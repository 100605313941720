import React from 'react';
import logo from './assets/images/logo_2.svg';
import logo2 from './assets/images/logo_2.svg';
import logo_text from './assets/images/data_management_tool.svg';
import './App.css';
import InfoCard from './components/InfoCard/InfoCard'
import InfoCardData from './components/InfoCard/data/InfoCardData'
import InfoCardNavigator from './components/InfoCardNavigator/InfoCardNavigator'
import { findByLabelText } from '@testing-library/dom';


function App() {
  return (
    <div className="App" style={{display:'flex', flexDirection:'column'}}>
      <div className={'header-section-container'}>
      <header className="App-header">
        <img src={logo} style={{width:100}} className="App-logo" alt="logo" />
        <img src={logo_text} style={{maxWidth:500, paddingBottom:20}} alt="logo" />
        {/*<p>
         <code>Datheron.com</code>
        </p>*/}
        <a
          className="button-1"
          href="mailto:info@datheron.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
        </a>
      </header>
      </div>
      <div className={'section-container'}>
        <div style={{maxWidth:400, padding:20, backgroundColor:"#ff9900", boxShadow:'5px 5px 1px rgba(97, 59, 2, 0.8)',color:'#FFFFFF', border:'1px solid #ff9900', fontSize:13, fontWeight:'bold', textAlign:'justify'}}>
          <p>
            <code>Datheron offers a reliable and easy to use solution for 
            data collection, analysis and presentation. Data collection 
            is a key process in decision making and with the rapid 
            evolution and awareness growth of information technology,
            it has become pertinent that the information is accurate, accessible 
            and reliable.
            </code>
          </p>
        </div>
       
        
      </div>
      <div className={'section-container'} style={{minHeight:250}}>
       <InfoCardNavigator>{InfoCardData.map(ic => (
          <InfoCard {...ic} />
        ))}</InfoCardNavigator>
      </div>
      <div className={'section-container'} style={{padding:0}}>
      <div style={{ position:'absolute', bottom:0, fontWeight:'normal', fontSize:12,backgroundColor:"#444444", width:'100%', height:'40px', display:'flex',  justifyContent:'center', flexDirection:'column', color:'#FFFFFF', verticalAlign:'middle'}}>{`${new Date().getFullYear()} Datheron Limited`}</div>
      </div>
      
    </div>
    
  );
}

export default App;
